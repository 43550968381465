.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}
.loading_center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  margin: auto;
  display: flex;
  justify-content: center;
}
.loading_center span {
  width: 8px;
  height: 100%;
  border-radius: 4px;
  background-color: lightgreen;
  animation: load 1s ease infinite;
  margin: 0 6px;
}
@keyframes load {
  0%,
  100% {
    transform: scaleY(1.5);
    background-color: lightgreen;
  }
  50% {
    transform: scaleY(0.8);
    background-color: lightblue;
  }
}

.loading_center span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading_center span:nth-child(3) {
  animation-delay: 0.4s;
}
.loading_center span:nth-child(4) {
  animation-delay: 0.6s;
}
.loading_center span:nth-child(5) {
  animation-delay: 0.8s;
}